* {
    margin: 0;
    padding: 0;
}
.dropdown-enter {
    opacity: 0;
    transform: translateY(-10px);
}
.rc-time-picker-input:-webkit-autofill,
.rc-time-picker-input:-webkit-autofill:focus,
.rc-time-picker-input:-webkit-autofill:hover {
    display: none !important;
    visibility: hidden !important;
    color: red !important;
}
.dropdown-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 100ms, transform 100ms;
}

.dropdown-exit {
    opacity: 1;
    transform: translateY(0);
}

.dropdown-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 100ms, transform 100ms;
}
.event-tooltip {
    position: absolute;

    z-index: 9999;
    display: block;

    max-width: 300px; /* Adjust the tooltip width as needed */
}
.border-red-600 {
    border: 1px solid red !important;
}
.important {
    color: red;
}
.pagination {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.pagination li {
    margin-right: 10px;
}

.pagination li a {
    display: inline-block;
    padding: 4px 8px;
    border: 1px solid #ccc;
    color: black;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.9rem;
}

.pagination li a:hover {
    background-color: #a9a3a3;
    color: #fff;
}

.pagination .active a {
    background-color: #a9a3a3;
    color: #fff;
    font-weight: bold;
}
.pagination .disabled a {
    background-color: lightgray;
    color: white;
    border-color: transparent;
}
.pagination .disabled a:hover {
    background-color: lightgray;
}
/* Styling previous and next buttons */
.pagination .previous a,
.pagination .next a {
    background-color: #1e87db;
    border: 1px solid #1e87db;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
}

/* Pagination separator style (e.g., "..." between pages) */
.pagination .break a {
    border: none;
    color: #1e87db;
}

@media screen and (max-width: 1000px) {
    .event-tooltip {
        display: none;
        /* color: rgba(4, 135, 54, 0.96);
        color: rgba(184, 26, 73, 0.96);
        color: rgba(123, 145, 11, 0.718); */
    }
}
.swal-title {
    margin: 0px;
    font-size: 18px;
}
.swal-text {
    background-color: #fefae3;
    padding: 17px;
    border: 1px solid #f0e1a1;
    display: block;
    margin: 10px 22px;
    text-align: center;
    color: #61534e;
}
.swal-icon {
    margin: 5px auto;
}
/* Customize pagination styles */
.pagination {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.pagination li {
    margin-right: 10px;
}

.pagination li a {
    display: inline-block;
    padding: 4px 8px;
    border: 1px solid #ccc;
    color: black;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.9rem;
}

.pagination li a:hover {
    background-color: #a9a3a3;
    color: #fff;
}

.pagination .active a {
    background-color: #a9a3a3;
    color: #fff;
    font-weight: bold;
}
.pagination .disabled a {
    background-color: lightgray;
    color: white;
    border-color: transparent;
}
.pagination .disabled a:hover {
    background-color: lightgray;
}
/* Styling previous and next buttons */
.pagination .previous a,
.pagination .next a {
    background-color: #1e87db;
    border: 1px solid #1e87db;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
}

/* Pagination separator style (e.g., "..." between pages) */
.pagination .break a {
    border: none;
    color: #1e87db;
}
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
