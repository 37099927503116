.modal {
    /* Default styles */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: white; */
    padding: 0;
    width: 700px;
    overflow: auto;

    /* Media queries for responsive adjustments */
    @media (max-width: 768px) {
        width: 80%;
    }

    @media (max-width: 480px) {
        width: 60%;
    }
}
.wrapper {
    display: grid;
    gap: 1rem;
    margin: 0.5rem;
}
.wrapper > button {
    justify-self: center;
    padding: 0.5rem 1rem;
    background-color: #1e87db;
    color: white;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    border: none;
    cursor: pointer;
    &:disabled {
        background-color: rgb(184, 179, 179);
    }
}
.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    /* Media queries for responsive adjustments */
    @media (max-width: 480px) {
        background-color: rgba(0, 0, 0, 0.8);
    }
}

.delete {
    border: none;
    font-size: 1.1rem;
    background-color: inherit;
    cursor: pointer;
}
.students-select {
    position: relative;
    width: 90%;
    padding: 1rem 0;
    justify-self: center;
}
.students-select input {
    width: 100%;
    height: 3rem;
    text-indent: 0.5rem;
    outline: none;
}
.students-select > button {
    position: absolute;
    right: 0;
    height: 3.3rem;
}
.students-options {
    position: absolute;
    top: 60px;
    height: 5rem;
    overflow-y: scroll;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid lightgray;
}

.students-options button {
    width: 100%;
    border: none;
    background-color: inherit;
    text-align: left;
    padding: 0.5rem;
    cursor: pointer;

    border-bottom: 1px solid lightgray;
}

.students-options button:hover {
    background-color: lightgray;
}
